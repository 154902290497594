/* eslint-disable */

import React from "react"
class GoogleTagManagerHeader extends React.Component {
    
    componentDidMount() {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            'event': 'gtm.js' 
        })
        var f = document.getElementsByTagName('script')[0];
        var j = document.createElement('script');
        var l = 'dataLayer';
        var dl = l!='dataLayer'?'&l='+l:'';
        j.async = true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+'GTM-NFLXJ3C6'+dl;
        f.parentNode.insertBefore(j,f);
    }

    render() {
        return (
            <script type="text/javascript">
            </script>
        );
    }
}

export default GoogleTagManagerHeader;