import React from "react"
import { css } from "@emotion/core"
import pnButtonStyles from "./pnButton.module.css";



class PNButton extends React.Component {

    constructor(props) {
        super(props);
        this.state= {
            url: props.url,
            panelHeaderCss: css`
                background-image: url(${props.imgPath});
                height: 48px;
                width: 1024px;
                display: flex;
            `,
            linkCss: css`
                text-decoration: none;
            `,
            text: props.text
        }
    }

    componentDidMount() {
        let themeValue = new URLSearchParams(window.location.search).get('theme');
        if (!themeValue) themeValue = 'default';
        this.setState({
            url: `${this.state.url}?theme=${themeValue}`
        });
    }

    render() {
        return (
            <a css={this.state.linkCss} href={this.state.url}>
                <div css={this.state.panelHeaderCss}>
                    <p className={pnButtonStyles.pnButtonTitle}>{this.state.text}</p>
                </div>
            </a>
        )
    }
}

export default PNButton;