/* eslint-disable */

import React from "react"
class GoogleTagManagerBody extends React.Component {

    render() {
        return (
            <noscript>
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NFLXJ3C6"
                    height="0" width="0" style={{display:"none", visibility: "hidden"}}>
                </iframe>
            </noscript>
        );
    }
}

export default GoogleTagManagerBody;